@import 'styles/variables.module.scss';

.pageLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  position: sticky;
  z-index: 11;
  top: 0;
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: var(--gutter);
  margin-bottom: var(--gutter);
}

.box {
  flex-grow: 1;
}

