.editableTitle {
  display: flex;
  align-items: center;
}

.backLink {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.image {
  margin-right: 16px !important;
}

.title {
  flex-grow: 1;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  &_editable {
    cursor: pointer;
  }
}

