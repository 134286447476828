.empty {
  padding: 80px 0;

  :global {
    .ant-empty-description {
      margin-left: auto;
      margin-right: auto;
      max-width: 360px;
    }
  }
}

