@import 'styles/variables.module.scss';

.sidebarMenu {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.link {
  position: relative;
  display: flex;
  align-items: center;
  line-height: $line-height-sm;
  font-weight: 800;
  color: $text-disabled;

  &:hover {
    color: $text-primary;
  }

  &_active {
    color: $primary-color !important;
  }

  &__icon {
    display: block;
    padding: 8px;
    margin-right: 12px;
    border-radius: 8px;
    background-color: $primary-color-4;
    line-height: 1;
    color: currentColor;
  }

  &_active &__icon {
    background-color: $primary-color;
    color: $white;
  }
}

