.notFound {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin-top: var(--gutter);
  margin-bottom: var(--gutter);
  background: center center / contain no-repeat url(./background.png);
}

