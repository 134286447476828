@import 'styles/variables.module.scss';

@mixin toolbar {
  display: flex;
  padding: 8px;
  border: 1px solid $border-color;
  border-radius: 12px 12px 0 0;
  background-color: $header-background;
}

@mixin separator {
  border: 1px solid $border-color;
  border-width: 0 1px 0 0;
  margin: 0 4px 0 0;
  padding: 0;
  width: 0;
  height: auto;
}

@mixin button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px 0 0;
  padding: 3px;
  border: none;
  font-size: $font-size-md;
  line-height: 1;
  background: none;
  cursor: pointer;
}

@mixin button_active {
  color: $primary-color;
}

.editor {
  display: flex;
  flex-direction: column;

  width: 100%;

  &_rich {
    height: 100%;
  }

  justify-content: space-between;

  &_rich &__wrapper {
    flex-grow: 1;
    padding: 20px;
    border: 1px solid $border-color;
    border-top: none;
    border-radius: 0 0 12px 12px;
  }

  &__interview_wrapper {
    flex-direction: row;
    width: 75%;
    padding-right: var(--gutter);
  }
}

.editor__interview {
  flex-direction: row;
}

.toolbar {
  @include toolbar();

  &_sticky {
    border-radius: 0;
  }

  &__group {
    display: flex;
  }

  &__separator {
    @include separator();
  }
}

.toolbarButton {
  @include button();

  &_active {
    @include button_active();
  }
}

.questions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.question {
  display: flex;
  gap: 8px;
  cursor: pointer;

  &:hover {
    .question__text {
      color: $primary-color;
    }
  }
}

.question__color_square {
  width: 20px;
  height: 20px;
  border-radius: 2px;
}

.questionMarks {
  position: relative;
  z-index: 10;
  width: 25%;
}

.questionMarkItem {
  position: absolute;
  max-width: 100%;
  padding: 5px 25px 5px 8px;
  border-radius: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: bold;
}

.questionMarkCloseIcon {
  position: absolute;
  right: 8px;
  top: 9px;
  cursor: pointer;
}

.questionElementFocused span {
  background-color: inherit;
}

.questionsPopover :global(.ant-popover-inner) {
  max-height: 400px;
  overflow: auto;
}

.tagList_container {
  padding-top: 4px;
  padding-bottom: 4px;
}

.tagList {
  height: 100%;
  overflow-y: auto;
}

.tagListItem {
  &:not(:first-child) {
    margin-top: 16px;
  }

  &__children {
    margin-left: 32px;

    .tagListItem {
      margin-top: 16px;
    }

    .tagListItem:last-child .tagListItemLabel__mark {
      &::before {
        height: 10px;
      }

      &::after {
        top: 8px;
      }
    }

    .tagListItemLabel__mark {
      background-color: transparent;

      &::before,
      &::after {
        display: block;
        position: absolute;
        content: '';
        background-color: var(--color);
        border-radius: 2px;
      }

      &::before {
        width: 2px;
        height: 12px;
      }

      &::after {
        top: 5px;
        width: 10px;
        height: 2px;
      }
    }
  }
}

.tagListItemLabel {
  display: flex;
  position: relative;
  padding-left: 32px;
  font-size: 16px;
  line-height: 1.4;
  cursor: pointer;

  &:hover {
    color: #6284fd;
  }

  &__mark {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 16px;
    height: 16px;
    content: '';
    background-color: var(--color);
    border-radius: 4px;
    transform: translateY(-50%);
  }
}

