@import 'styles/variables.module.scss';

.interviewPage {
}

.box {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
}

.spin {
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.addFile {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  align-items: stretch;

  & img {
    max-width: 100%;
  }

  &__img {
    width: 60px;
  }

  &__cloud {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__left,
  &__right {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    text-align: center;
    padding: 10px;
    background-color: $gray-light;
    border: 1px solid $border-color;
    cursor: pointer;
    transition: $transition;
    border-radius: 8px;
  }

  &__left:hover,
  &__right:hover {
    transform: translateY(-3px);
  }

  &__top {
    flex: 1 1 auto;
  }

  &__right {
  }

  &__clouds {
    display: flex;
    gap: 10px;
  }

  &__or {
    align-self: center;
    white-space: nowrap;
  }
}

