@import 'styles/variables.module.scss';

.authLayout {
  min-height: 100vh;
  display: flex;
  align-items: center;
  vertical-align: middle;
  background: right 10% center / contain no-repeat url(./background.png),
    linear-gradient(180deg, #6284fd 0%, #1b4af0 100%);
}

.box {
  width: 700px;
  margin: var(--gutter) 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 52px;
  margin-bottom: 40px;
}

.footer {
  margin-top: 60px;
}

