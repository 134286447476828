@import 'styles/variables.module.scss';

.playerControls {
  display: flex;
  gap: 16px;
  align-items: center;
}

.bar {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  padding: 4px 0;
  cursor: pointer;

  &__action {
    height: 6px;
    background-color: $border-color;
    border-radius: 4px;
  }

  &__tick {
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 0;
    background-color: $accent-color;
    border-radius: 4px;
  }
}

.rate {
  min-width: 38px;
  text-align: right;

  &__menu {
    text-align: right !important;
  }
}

