@import 'styles/variables.module.scss';

.message {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &__image {
    flex-shrink: 0;
    margin-right: 18px !important;
  }

  &__content {
    flex-grow: 1;
    width: calc(100% - 48px - 18px);
  }

  &__play {
    margin-left: 8px;
  }

  &__name {
    display: inline-block;
    padding-top: 5px;
    margin-top: -5px;
    cursor: pointer;
  }

  &__text {
    padding: 0 !important;
    transition: $transition;

    &_active {
      box-shadow: 0px 0px 0px 4px #d9d9d9;
      border-radius: 1px;
      background-color: #d9d9d9;
    }
  }
}

.tagList {
  display: flex;
  flex-direction: column;
}

.tagListItem {
  display: flex;
  padding: 8px 16px;
  width: fit-content;
  max-width: 100%;
  font-weight: 700;
  font-size: 16px;
  border-radius: 8px;
  cursor: default;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &__label {
    min-width: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover > .actionList {
    display: none;
  }
}

.actionList {
  display: none;
  margin-left: 8px;

  &__item {
    cursor: pointer;
  }
}

