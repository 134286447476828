.report {
}

.question {
  &_color {
    width: 38px;
    height: 6px;
    margin: -6px 0 0;
    border-radius: 4px;
  }
}

.table {
  &_avatar {
    margin: -3px 10px -3px 0 !important;
  }

  &_conclusion {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &_answer:last-child {
    margin-bottom: 0;
  }
}

