@import 'styles/variables.module.scss';

.selectChannel {
  width: 450px;

  &__list {
    max-height: 240px;
    overflow-y: auto;
  }

  &__form {
    display: flex;
    align-items: center;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__image {
    flex-shrink: 0;
  }

  &__input {
    margin-left: 8px;
  }
}

