@import 'styles/variables.module.scss';

.linkGroup {
  display: flex;
  padding: 4px;
  border-radius: 30px;
  background-color: $primary-color-4;
}

.link {
  padding: 8px 20px;
  border-radius: 30px;
  font-size: $font-size-md;

  &_active {
    background-color: $primary-color;
    color: $white;

    &:hover {
      color: $white;
    }
  }
}

