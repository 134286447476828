@import 'styles/variables.module.scss';

.app {
  min-height: 100vh;
  min-width: $container-width-sm;
}

.app__help {
  position: fixed !important;
  right: 20px;
  bottom: 20px;
}
