@import 'styles/variables.module.scss';

.editableImage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 35%;
  overflow: hidden;
  background-color: $primary-color-4;

  &_small {
    width: 48px;
    height: 48px;
  }

  &_large {
    width: 160px;
    height: 160px;
  }

  &__spin {
    width: 32px;
    height: 32px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__icon {
    font-size: 20px;
  }

  &_small &__icon {
    font-size: 16px;
  }

  &_large &__icon {
    font-size: 40px;
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    background-color: $primary-color-4;
    color: $primary-color;
    opacity: 0;
    transition: $transition;

    &:hover {
      opacity: 0.5;
    }

    &_pointer {
      cursor: pointer;
    }

    &_empty {
      opacity: 1;
    }
  }
}

