@import 'styles/variables.module.scss';

.title {
}

.paragraph {
  &_small {
    font-size: $font-size-sm;
  }

  &_middle {
    font-size: $font-size-md;
  }

  &_large {
    font-size: $font-size-lg;
  }
}

.text {
}

.link {
}

