@import 'styles/variables.module.scss';

.marketplacePage {
  &__spinWrapper {
    text-align: center;
  }

  &__actions {
    margin-top: 24px;
  }

  &__actionsContainer {
    text-align: center;

    &_sticky {
      background-color: $component-background;
      box-shadow: 0 24px 0 0 $component-background, 0 -6px 10px 12px $component-background;
    }
  }
}

