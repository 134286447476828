.videoPlayer {
  &__wrapper {
    position: relative;
    margin-bottom: 8px;
    padding-top: 56.25%;

    &_loading {
      display: none;
    }

    &_sticky {
      padding-top: 0;
      height: 200px;
    }
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
  }
}

