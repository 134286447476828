@import 'styles/variables.module.scss';

.header {
  background-color: $component-background;
  box-shadow: $shadow-sm;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: $header-height;
}

.slot {
  flex-grow: 1;
  margin-right: 50px;
}

.menu {
  align-self: stretch;
  margin-left: auto !important;
  margin-right: 75px !important;
}

