@import 'styles/variables.module.scss';

.imageGallery {
  :global {
    .ant-upload-list-picture-card {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      &::before {
        display: none;
      }
    }

    .ant-upload-list-picture-card-container,
    .ant-upload.ant-upload-select-picture-card {
      margin: 0;
    }

    .ant-upload-select-picture-card {
      order: -1;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon {
      z-index: 10;
      width: 16px;
      margin: 0 4px;
      color: #ffffffd9;
      font-size: 16px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        color: #ffffff;
      }
    }

    .ant-upload-list-item-success .ant-btn {
      display: none;
    }
  }
}

.upload {
  &__icon {
    font-size: $font-size-lg;
    color: $primary-color !important;
  }

  &__text {
    margin-top: 8px;
  }
}

