.project {
}

.pageHeader {
  height: 100%;
  display: flex;
  flex-direction: column;

  :global {
    .ant-page-header-content {
      flex-grow: 1;
    }
  }
}

