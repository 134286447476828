$font-size: 14px;
$font-size-sm: $font-size - 2px;
$font-size-md: $font-size + 2px;
$font-size-lg: $font-size + 4px;

$line-height-sm: 1.15;

$screen-lg: 1600px;

$container-gutter: 40px;
$container-gutter-sm: 26px;
$container-width: 1720px + $container-gutter * 2;
$container-width-sm: 1388px + $container-gutter-sm * 2;

:root {
  --gutter: #{$container-gutter-sm};
}

@media (min-width: $screen-lg) {
  :root {
    --gutter: #{$container-gutter};
  }
}

$white: #fff;

$gray-light: #f8f8fa;

$component-background: #fff;
$header-background: #f5f4f8;

$primary-color: #5452fb;
$primary-color-4: #ecf0ff;
$accent-color: #ff7f37;
$shadow-color: #787d88;

$border-color: #cfd0d8;

$text-primary: #000;
$text-disabled: #a7aab4;
$text-secondary: #747884;

$success: #52c41a;
$danger: #f5222d;

$shadow: 0px 0px 20px rgba($shadow-color, 0.2);
$shadow-sm: 0px 0px 10px rgba($shadow-color, 0.15);
$shadow-md: 0px 0px 30px rgba($shadow-color, 0.3);

$header-height: 80px;

$transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

:export {
  headerHeight: $header-height;
  success: $success;
  danger: $danger;
}

