.table {
  :global {
    .ant-table-selection-column {
      padding-left: 16px;
      padding-right: 16px;
    }

    .ant-table-cell {
      vertical-align: top;
    }
  }
}

