@import 'styles/variables.module.scss';

.box {
  background-color: $component-background;
  padding: var(--gutter);
  box-shadow: $shadow;
  border-radius: 32px;

  &_middle {
    padding: calc(var(--gutter) + 20px);
    box-shadow: $shadow-md;
    border-radius: 40px;
  }

  &_small {
    padding: 24px;
    box-shadow: $shadow-md;
    border-radius: 8px;
  }

  &_colored {
    background-color: $primary-color-4;
    box-shadow: none;
    border-radius: 8px;
    border: 1px solid $primary-color;
  }
}

