.tariff {
}

.listItem {
  position: relative;
  display: grid;
  grid-template-columns: 120px 100px 1fr;

  &::before {
    display: block;
    content: '';
    position: absolute;
    left: -0.8em;
    top: 0.7em;
    width: 0.25em;
    height: 0.25em;
    border-radius: 50%;
    background-color: currentColor;
  }
}

