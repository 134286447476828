.companies {
  &__checkAll {
    margin-bottom: 24px !important;
  }

  &__item:not(:last-child) {
    margin-bottom: 24px;
  }
}

.company {
  &__checkbox :global {
    .ant-checkbox {
      top: 0.1em;
    }
  }

  &__infoWrapper {
    display: flex;
    gap: 16px;
    padding-left: 24px;
  }

  &__image {
    align-self: flex-start;
    width: 160px;
    flex-shrink: 0;
  }

  &__info {
    flex-grow: 1;
  }

  &__collapse :global {
    .ant-collapse-header {
      padding: 0 !important;
    }

    .ant-collapse-content-box {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

