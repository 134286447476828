@import 'styles/variables.module.scss';

.tree {
  counter-reset: item;
  margin: -16px calc(var(--gutter) * -1) 0;
  padding: 16px var(--gutter);
  list-style: none;

  :global {
    li {
      position: relative;
    }

    ul {
      counter-reset: item;
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
}

.draggingSource {
  opacity: 0.3;
}

.placeholderContainer {
  position: relative;
}

.customNode {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 44px;
  margin-bottom: 10px;
  border-radius: 2px;

  &::before {
    content: counters(item, '.') '.';
    counter-increment: item;
    width: 24px;
  }

  .dropTarget > &_droppable:last-child {
    background: rgba($primary-color, 0.2);
  }

  &__grabButton {
    order: -1;
    padding: 5px;
    color: $text-secondary !important;
    cursor: grab;
    transition: $transition;

    &:hover {
      color: $text-primary !important;
    }
  }

  &__number {
    width: 20px;
  }

  &__deleteButton {
    margin: 0;
    padding: 5px;
    border: none;
    border-radius: 0;
    background: none;
    cursor: pointer;
    color: $text-secondary;

    &:hover {
      color: $text-primary;
    }
  }
}

.placeholder {
  position: absolute;
  top: -7px;
  right: 0;
  left: 0;
  height: 4px;
  border-radius: 2px;
  background-color: $primary-color;

  &::before {
    display: none;
  }
}

.color {
  padding: 0 4px;
  cursor: pointer;

  &__action {
    width: 6px;
    height: 38px;
    border-radius: 4px;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 152px;
  }

  &__item {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 0px 2px $border-color;
    }
  }
}

