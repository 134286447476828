.onboardingPopover {
  position: relative;
  padding: 32px 32px 24px;
}

.close {
  position: absolute !important;
  right: 8px;
  top: 8px;
}

.actions {
  text-align: right;
}

