@import 'styles/variables.module.scss';

.profileAvatar {
  display: flex;
  align-items: center;
  margin-bottom: 48px;

  &__image {
    flex-shrink: 0;
    margin-right: var(--gutter);
  }

  &__paragraph {
    margin-bottom: 0 !important;
  }
}

