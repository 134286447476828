@import 'styles/variables.module.scss';

$sidebar-with: 200px;
$sidebar-with-lg: 264px;

:root {
  --sidebar-with: #{$sidebar-with};
}

@media (min-width: $screen-lg) {
  :root {
    --sidebar-with: #{$sidebar-with-lg};
  }
}

.projectLayout {
  display: grid;
  grid-template-areas: 'sidebar header' 'sidebar container';
  grid-template-columns: var(--sidebar-with) 1fr;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
}

.sidebar {
  grid-area: sidebar;
  position: fixed;
  z-index: 12;
  top: 0;
  bottom: 0;
  left: 0;
  width: var(--sidebar-with);
}

.header {
  grid-area: header;
  position: sticky;
  z-index: 11;
  top: 0;
}

.container {
  grid-area: container;
  display: flex;
  margin-top: var(--gutter);
  margin-bottom: var(--gutter);
}

.box {
  flex-grow: 1;
  min-height: 100%;
  max-width: calc(100vw - var(--sidebar-with) - var(--gutter) - var(--gutter));
  min-width: calc($container-width-sm - var(--sidebar-with));
}

.additionalBox {
  flex-shrink: 0;
  width: 500px;
  min-height: 100%;
  margin-left: var(--gutter);
}

