@import 'styles/variables.module.scss';

.baseMenu {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  display: flex;
  align-items: center;
  gap: 8px;

  &:not(:last-child) {
    margin-right: 30px;
  }
}

.link {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 25px 0;
  color: $text-secondary;

  &:hover {
    color: $text-primary;
  }

  &_active {
    color: $text-primary;

    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 4px;
      border-radius: 4px 4px 0px 0px;
      background-color: $accent-color;
    }
  }
}

.hint {
  color: $text-secondary;
  cursor: pointer;

  &:hover {
    color: $text-primary;
  }
}

