@import 'styles/variables.module.scss';

.editableChannel {
  display: flex;
  border-radius: 18px;

  &_new {
    align-items: center;
  }

  &_current {
    background-color: $gray-light;
  }

  &__image {
    flex-shrink: 0;
  }

  &__nameWrapper {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-left: 20px;
    cursor: pointer;

    &:hover {
      :global {
        .ant-typography {
          color: $primary-color;
          transition: $transition;
        }
      }
    }

    :global {
      .ant-typography {
        width: 100%;
      }
    }
  }

  &__name {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    cursor: pointer;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-right: 8px;
    opacity: 0;
    transition: $transition;
  }

  &:hover &__actions {
    opacity: 1;
  }
}
