@import 'styles/variables.module.scss';

$border-radius: 12px;

.transcription {
  position: relative;
  padding: 20px;
  border-radius: $border-radius;
  background-color: $gray-light;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &__removeButton {
    position: absolute !important;
    top: 14px;
    right: 14px;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  &__headerInfo {
    flex-grow: 1;
  }

  &__name {
    margin-right: 40px;
    cursor: pointer;

    &:global(.ant-typography-edit-content) + * {
      margin-top: -10px !important;
    }
  }

  &__player {
    padding: 8px 0;
    background-color: $gray-light;
  }

  &__icon {
    margin-right: 8px;
    color: $accent-color;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &__infoTitle {
    margin-bottom: 0 !important;
  }

  &__content {
    outline: 0px solid transparent;
  }
}

.payment {
  padding: 10px 0 20px;
  margin-bottom: -20px;
  background-color: $gray-light;
  box-shadow: 0px -8px 6px -6px $gray-light;
  text-align: center;
}

