@import 'styles/variables.module.scss';

.sidebar {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  background-color: $component-background;
  box-shadow: $shadow-sm;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: $header-height;
}

.body {
  flex-grow: 1;
  padding: var(--gutter);
}

